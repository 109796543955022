import { useStyles } from './gatedContentSection.styles';
import RichText from '@components/RichText';
import { H2, Grid } from '#ui';
import Form from '@components/Form';
import type { FunctionComponent } from 'react';
import type { GatedContentSectionProps as Props } from './types';

const GatedContentSection: FunctionComponent<Props> = ({
  heading,
  copy,
  duration,
  recordedOn,
  form,
  order,
}) => {
  const Styles = useStyles({
    order,
    backgroundColor: 'White',
  });
  return (
    <section
      className={`gatedContentSection`}
      aria-label={heading || undefined}
      sx={Styles.ROOT}
    >
      <Grid
        noPadding
        as="article"
        className="copyImage__wrapper"
        sx={Styles.GATED_GRID}
      >
        <div sx={Styles.LEFT_CONTENT}>
          {!!heading?.length && <H2 sx={Styles.HEADING}>{heading}</H2>}
          <div sx={Styles.RICH_TEXT}>
            {copy?.json && <RichText {...copy} />}
          </div>
        </div>
        <section sx={Styles.RIGHT_CONTENT} aria-label="form help">
          {recordedOn && (
            <div sx={Styles.METADATA}>
              <span>Recorded On</span>
              {recordedOn}
            </div>
          )}
          {duration && (
            <div sx={Styles.METADATA}>
              <span sx={Styles.METADATA}>Duration</span>
              {duration}
            </div>
          )}

          {form && <Form {...form} order={0} />}
        </section>
      </Grid>
    </section>
  );
};

export default GatedContentSection;
