import { getSpacing } from '@lib/utils';
import { ColorHex } from '#constants';
import { useContrast } from '#hooks';

import type { GatedContentSectionStyles as GCSS } from './types';
import type { ThemeUIStyleObject as TSO } from 'theme-ui';

export const useStyles = ({
  order,
  backgroundColor: bkg,
}: GCSS): typeof Styles => {
  const { text: color } = useContrast(bkg);
  const backgroundColor = ColorHex[bkg || 'White'];

  const Styles = {
    ROOT: {
      marginBlockStart: getSpacing(order),
    },
    GATED_GRID: {
      // display: 'flex',
      // inlineSize: '100%',
      backgroundColor,
      // flexDirection: ['column', 'row'],
      // alignItems: 'center',
      // justifyContent: 'center',
    } as TSO,

    METADATA: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      marginBlockEnd: [0, null, 5],
      span: {
        variant: 'styles.h5',
        marginBlockEnd: ['0px', 1],
      },
    } as TSO,

    RICH_TEXT: {
      p: { color },
      ol: {
        marginInlineStart: 4,
        paddingBlockStart: 2,
        paddingBlockEnd: 2,
        listStylePosition: 'inside',
        whiteSpace: 'pre-wrap',
        li: {
          listStylePosition: 'outside',
        },
        '& ol': {
          listStyleType: 'lower-alpha',

          '& ol': {
            listStyleType: 'lower-roman',
          },
        },
      },
      ul: {
        paddingBlockStart: 2,
        paddingBlockEnd: 2,
        marginInlineStart: 4,
        listStylePosition: 'inside',
        whiteSpace: 'pre-wrap',
        li: {
          listStylePosition: 'outside',
        },
      },
    } as TSO,

    HEADING: {
      marginBlockStart: [4, '0px', '0px'],
      color,
    } as TSO,

    RIGHT_CONTENT: {
      display: 'flex',
      flexDirection: 'column',
      flexFlow: 'row wrap',
      paddingInlineStart: [4, 0, '0px'],
      paddingInlineEnd: [4, '0px'],
      paddingBlockStart: [0, 0, '0px'],
      paddingBlockEnd: 6,
      gridColumn: ['1/-1', '3/span 7', '12/ span 4'],
      justifySelf: ['center', 'stretch', 'stretch'],
      justifyContent: 'flex-start',
      section: {
        flexBasis: '100%',
      },
      alignContent: 'flex-start',
      'div:nth-of-type(1)': {
        marginInlineEnd: '0px',
      },
    } as TSO,

    LEFT_CONTENT: {
      display: 'flex',
      flexDirection: 'column',
      paddingInlineStart: [4, '0px'],
      paddingInlineEnd: [4, 5],
      paddingBlockStart: [5, 1],
      paddingBlockEnd: [5, 5, 6],
      gridColumn: ['1/-1', '2/ span 8', '2/ span 9'],
    } as TSO,

    CONTENT: {
      display: 'flex',
      flexFlow: ['row wrap'],
      inlineSize: '100%',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexBasis: '40%',
      paddingInlineStart: [4, 5],
      paddingInlineEnd: [4, 5],
      paddingBlockStart: [5, 6],
      paddingBlockEnd: 6,
    } as TSO,
  };

  return Styles;
};
